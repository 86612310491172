import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import { GlobalPageContext } from '../types'
import { AboutQuery } from '../../graphql-types'
import createPageContainerProps from '../createPageContainerProps'
import PageContainer from '../components/PageContainer'

import {
  WhoWeAre,
  LifeAtOrmo,
  WantToJoin,
  SloganSlider,
  OurTeamAndValues,
} from '../components/About'

type Props = PageProps<AboutQuery, GlobalPageContext>

const AboutPage: FC<Props> = ({ data, pageContext }) => {
  const {
    contactInfo,
    seoTranslations,
    commonTranslations,
    aboutTranslations,
    whoWeAreImage,
    ourTeam1stImage,
    ourTeam2ndImage,
    lifeAtOrmo1stImage,
    lifeAtOrmo2ndImage,
    lifeAtOrmo3rdImage,
  } = data

  const t = { ...seoTranslations!.json!, ...commonTranslations!.json!, ...aboutTranslations!.json }

  return (
    <PageContainer
      translations={t}
      {...createPageContainerProps(data, pageContext)}
    >
      <WhoWeAre
        images={[
          whoWeAreImage?.childImageSharp,
        ]}
      />

      <OurTeamAndValues
        images={[
          ourTeam1stImage?.childImageSharp,
          ourTeam2ndImage?.childImageSharp,
        ]}
      />

      <SloganSlider />

      <LifeAtOrmo
        images={[
          lifeAtOrmo1stImage?.childImageSharp,
          lifeAtOrmo2ndImage?.childImageSharp,
          lifeAtOrmo3rdImage?.childImageSharp,
        ]}
      />

      <WantToJoin
        contact={contactInfo?.siteMetadata?.contact}
      />
    </PageContainer>
  )
}

export default AboutPage

export const pageQuery = graphql`
  fragment AboutPageImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
      original {
        width
      }
    }
  }

  query About($pathRegex: String!, $lang: String!, $pageId: String!) {
    ...PageData
    ...ContactInfo

    aboutTranslations: file(
      name: { regex: $pathRegex }
      extension: { eq: "json" }
      absolutePath: { regex: "/translations/" }
    ) {
      json: childTranslationsJson {
        whoWeAre {
          title
          paragraph
        }
        ourTeamAndValues {
          title
          paragraph1
          paragraph2
        }
        lifeAtOrmo {
          title
          section1 {
            title
            paragraph
          }
          section2 {
            title
            paragraph
          }
          section3 {
            title
            paragraph
          }
        }
        slogan
        wantToJoinUs
      }
    }

    whoWeAreImage: file(name: { eq: "who_we_are" }) {
      ...AboutPageImage
    }
    ourTeam1stImage: file(name: { eq: "our_team_1" }) {
      ...AboutPageImage
    }
    ourTeam2ndImage: file(name: { eq: "our_team_2" }) {
      ...AboutPageImage
    }
    lifeAtOrmo1stImage: file(name: { eq: "life_at_ormo_1" }) {
      ...AboutPageImage
    }
    lifeAtOrmo2ndImage: file(name: { eq: "life_at_ormo_2" }) {
      ...AboutPageImage
    }
    lifeAtOrmo3rdImage: file(name: { eq: "life_at_ormo_3" }) {
      ...AboutPageImage
    }
  }
`
