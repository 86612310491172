import React, { FC } from 'react'
import { Flex, Text } from 'rebass'

import useIntl from '../../states/useIntl'
import LinkExternal from '../../ui/LinkExternal'
import { ContactInfoFragment } from '../../../graphql-types'

import mailIconUrl from '../../content/images/icons/mail.svg'
import phoneIconUrl from '../../content/images/icons/phone.svg'

type Props = NonNullable<Required<ContactInfoFragment['contactInfo']>>['siteMetadata']

const WantToJoin: FC<Props> = ({ contact }) => {
  const { t } = useIntl()

  return (
    <Flex
      my={6}
      alignItems="center"
      flexDirection="column"
    >
      <Text
        as="h2"
        color="text"
        fontSize={32}
        mb={3}
      >
        {t('wantToJoinUs')}
      </Text>
      <Flex
        alignItems="center"
        mb={3}
      >
        <img
          width={24}
          height={24}
          src={mailIconUrl}
          alt="e-mail"
        />
        <LinkExternal
          ml={2}
          color="text"
          fontSize={16}
          href={`mailto:${contact?.machine?.careerEmail}`}
        >
          {contact?.display?.careerEmail}
        </LinkExternal>
      </Flex>
      <Flex
        alignItems="center"
        mb={3}
      >
        <img
          width={24}
          height={24}
          src={phoneIconUrl}
          alt="phone number"
        />
        <LinkExternal
          ml={2}
          color="text"
          fontSize={16}
          href={`tel:${contact?.machine?.phone}`}
        >
          {contact?.display?.phone}
        </LinkExternal>
      </Flex>
    </Flex>
  )
}

export default WantToJoin
