import React, { FC } from 'react'
import { Box, Text, Flex } from 'rebass'
import Img, { FluidObject } from 'gatsby-image'
import { AboutPageImageFragment } from '../../../graphql-types'
import useIntl from '../../states/useIntl'
import { wrapperWidth, imageBorderRadius, containerWidth } from './common'

type Props = {
  images: AboutPageImageFragment['childImageSharp'][]
}

const OurTeamAndValues: FC<Props>  = ({ images }) => {
  const { t } = useIntl()
  const image1 = images[0]!
  const image2 = images[1]!

  const rightTopChild = (
    <Box
      pr={3}
      pl={[3, 3, 6]}
      maxWidth={`calc((min(100vw, ${wrapperWidth}px) * 0.5625) - (min(100vw, ${wrapperWidth}px) - ${containerWidth}px) / 2)`}
    >
      <Text
        mb={4}
        as="h2"
        color="text"
        mt={[5, 5, 6]}
        fontSize={[24, 24, 32]}
      >
        {t('ourTeamAndValues.title')}
      </Text>
      <Text
        color="text"
        fontSize={[14, 14, 16]}
        dangerouslySetInnerHTML={{ __html: t('ourTeamAndValues.paragraph1') }}
      />
    </Box>
  )

  const leftChild = (
    <Flex flexDirection="column">
      <Flex
        mt={[5, 5, 0]}
        mr={[5, 5, 0]}
        overflow="hidden"
        alignItems="flex-end"
        flexDirection="column"
      >
        <Box
          overflow="hidden"
          style={{ borderBottomRightRadius: imageBorderRadius }}
          width={['100%', '100%', image1.original!.width!]}
        >
          <Img
            alt={t('ourTeamAndValues.title')}
            fadeIn={false}
            loading="eager"
            fluid={image1.fluid as FluidObject}
            style={{ display: 'block' }}
          />
        </Box>
      </Flex>

      <Flex
        overflow="hidden"
        alignItems="flex-end"
        flexDirection="column"
        maxWidth={image1.original!.width}
      >
        <Text
          pl={3}
          color="text"
          mt={[5, 5, 6]}
          mr={[3, 3, 0]}
          fontSize={[14, 14, 16]}
          maxWidth={`calc((min(100vw, ${wrapperWidth}px) * 0.4375) - (min(100vw, ${wrapperWidth}px) - ${containerWidth}px) / 2)`}
          dangerouslySetInnerHTML={{ __html: t('ourTeamAndValues.paragraph2') }}
        />
      </Flex>
    </Flex>
  )

  const rightBottomChild = (
    <Flex
      pl={[5, 5, 6]}
      justifyContent="flex-end"
    >
      <Box
        mt={[5, 5, 6]}
        minWidth={['100%', '100%', 'inherit']}
      >
        <Box
          overflow="hidden"
          width={['auto', 'auto', image2.original!.width!]}
          style={{
            borderTopLeftRadius: imageBorderRadius,
            borderBottomLeftRadius: imageBorderRadius,
          }}
        >
          <Img
            alt={t('ourTeamAndValues.title')}
            fadeIn={false}
            loading="eager"
            fluid={image2.fluid as FluidObject}
            style={{ display: 'block' }}
          />
        </Box>
      </Box>
    </Flex>
  )

  const leftSideWidth = '43.75%'
  const rightSideWidth = '56.25%'

  return (
    <Box
      mx="auto"
      maxWidth={wrapperWidth}
    >
      <Box
        overflow="hidden"
        width={['100%', '100%', rightSideWidth]}
        sx={{
          float: ['none', 'none', 'right'],
        }}
      >
        {rightTopChild}
      </Box>

      <Box
        overflow="hidden"
        width={['100%', '100%', leftSideWidth]}
        sx={{
          float: ['none', 'none', 'left'],
        }}
      >
        {leftChild}
      </Box>

      <Box
        overflow="hidden"
        width={['100%', '100%', rightSideWidth]}
        sx={{
          float: ['none', 'none', 'right'],
        }}
      >
        {rightBottomChild}
      </Box>

      <div style={{ clear: 'both' }} />
    </Box>
  )
}

export default OurTeamAndValues
