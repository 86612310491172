import React, { FC } from 'react'

import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const marquee = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`

const Container = styled.div`
  padding-left: 100%;
  will-change: transform;
  animation: ${marquee} 15s linear infinite;
`

const Content = styled.div`
  width: fit-content;
  white-space: nowrap;
  will-change: transform;
  animation: ${marquee} 15s linear infinite;
`

const Marquee: FC = ({ children }) => {
  return (
    <Container>
      <Content>
        {children}
      </Content>
    </Container>
  )
}

export default Marquee
