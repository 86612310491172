import React, { FC } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { Flex, Box, Text } from 'rebass'
import { AboutPageImageFragment } from '../../../graphql-types'
import { wrapperWidth, containerWidth, imageBorderRadius } from './common'
import useIntl from '../../states/useIntl'
import PageHeading from '../PageHeading'

type Props = {
  images: AboutPageImageFragment['childImageSharp'][]
}

const WhoWeAre: FC<Props> = ({ images }) => {
  const { t } = useIntl()
  const image = images[0]!

  return (
    <Box
      mx="auto"
      maxWidth={wrapperWidth}
    >
      <Flex
        mt={5}
        flexDirection={['column', 'column', 'row']}
      >
        <Flex
          flex={[1, 1, 7]}
          mb={[5, 5, 0]}
          justifyContent="flex-end"
        >
          <Box maxWidth={`calc((min(100vw, ${wrapperWidth}px) * 0.4375) - (min(100vw, ${wrapperWidth}px) - ${containerWidth}px) / 2)`}>
            <Box
              pl={3}
              pr={[3, 3, 5]}
            >
              <PageHeading my={4}>
                {t('whoWeAre.title')}
              </PageHeading>
              <Text
                color="text"
                fontWeight="bold"
                fontSize={[18, 18, 18, 24]}
                dangerouslySetInnerHTML={{ __html: t('whoWeAre.paragraph') }}
              />
            </Box>
          </Box>
        </Flex>
        <Box
          ml={[3, 3, 0]}
          flex={[1, 1, 9]}
          overflow="hidden"
        >
          <Box
            overflow="hidden"
            style={{ borderTopLeftRadius: imageBorderRadius }}
            width={['auto', 'auto', image.original!.width!]}
          >
            <Img
              alt={t('whoWeAre.title')}
              fadeIn={false}
              loading="eager"
              fluid={image.fluid as FluidObject}
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default WhoWeAre
