import React, { FC } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { Flex, Box, Text } from 'rebass'
import { AboutPageImageFragment } from '../../../graphql-types'
import { wrapperWidth, containerWidth } from './common'
import useIntl from '../../states/useIntl'

type Props = {
  images: AboutPageImageFragment['childImageSharp'][]
}

const LifeAtOrmo: FC<Props> = ({ images }) => {
  const { t } = useIntl()
  const illustrationsWidthDesktop = images.reduce(
    (acc, image) => acc + 20 + image?.original?.width!,
    -20,
  )

  return (
    <Flex
      mx="auto"
      maxWidth={wrapperWidth}
      flexDirection={['column', 'column', 'row']}
    >
      <Flex
        mb={[5, 5, 0]}
        justifyContent="flex-end"
        width={['auto', 'auto', '50%']}
      >
        <Box
          pl={3}
          pr={[3, 3, 5]}
          maxWidth={containerWidth / 2}
        >
          <Text
            mb={4}
            as="h2"
            color="text"
            fontSize={[24, 24, 32]}
          >
            {t('lifeAtOrmo.title')}
          </Text>
          <Text
            mb={3}
            color="text"
            fontSize={18}
            fontWeight="bold"
          >
            {t('lifeAtOrmo.section1.title')}
          </Text>
          <Text
            mb={4}
            color="text"
            fontSize={18}
            dangerouslySetInnerHTML={{ __html: t('lifeAtOrmo.section1.paragraph') }}
          />
          <Text
            mb={3}
            color="text"
            fontSize={18}
            fontWeight="bold"
          >
            {t('lifeAtOrmo.section2.title')}
          </Text>
          <Text
            mb={4}
            color="text"
            fontSize={18}
            dangerouslySetInnerHTML={{ __html: t('lifeAtOrmo.section2.paragraph') }}
          />
          <Text
            mb={3}
            color="text"
            fontSize={18}
            fontWeight="bold"
          >
            {t('lifeAtOrmo.section3.title')}
          </Text>
          <Text
            color="text"
            fontSize={18}
            dangerouslySetInnerHTML={{ __html: t('lifeAtOrmo.section3.paragraph') }}
          />
        </Box>
      </Flex>
      <Box
        flex={1}
        overflow="hidden"
        ml={[3, 3, '20px']}
      >
        <Flex
          justifyContent="flex-end"
          width={['auto', 'auto', illustrationsWidthDesktop]}
        >
          {images.map((image, i) => {
            const originalWidth = image?.original?.width!

            return (
              <Box
                key={i}
                ml={i > 0 ? ['10px', '10px', '20px'] : undefined}
                width={[originalWidth / 2, originalWidth / 2, originalWidth]}
              >
                <Img
                  alt={t('lifeAtOrmo.title')}
                  fadeIn={false}
                  loading="eager"
                  fluid={image?.fluid as FluidObject}
                />
              </Box>
            )
          })}
        </Flex>
      </Box>
    </Flex>
  )
}

export default LifeAtOrmo
