import React, { FC } from 'react'
import { Box, Text } from 'rebass'
import useIntl from '../../states/useIntl'
import Marquee from '../Marquee'

const SloganSlider: FC = () => {
  const { t } = useIntl()
  const smallShadow = '5px 5px 10px #DBE1EB, -5px -5px 10px #FFFFFF'
  const largeShadow = '10px 10px 20px #DBE1EB, -10px -10px 20px #FFFFFF'

  return (
    <Box
      py={[3, 3, 5]}
      overflow="hidden"
    >
      <Marquee>
        <Text
          fontSize={[70, 70, 200]}
          lineHeight="200px"
          fontWeight="bold"
          color="background"
          sx={{
            textShadow: [
              smallShadow,
              smallShadow,
              largeShadow,
            ],
          }}
        >
          {t('slogan')}
        </Text>
      </Marquee>
    </Box>
  )
}

export default SloganSlider
